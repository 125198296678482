<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="welcome-pro-dialog"
  fullscreen
  no-click-animation
  persistent
  transition="dialog-bottom-transition"
  width="100%"
)
  v-toolbar.px-2.elevation-0
    v-spacer
    v-spacer
    ui-btn(icon plain @click="cancel")
      ui-icon(name="close")
  .px-4
    ui-icon.c-black40.mb-4(name="user-round" size=64)

    h1.t-h1-mobile.mb-3(v-if="user") Добро пожаловать, {{ user.name }}!
    p.t-middle.mb-4 Вы зарегистрированы на Portal DA и Вам доступен базовый функционал.
    p.t-middle.mb-10 Продолжите регистрацию сейчас или в другое время в личном кабинете для получения PRO-аккаунта.

    h4.t-h4-mobile.mb-4 Преимущества PRO-аккаунта
    ul.mb-11.pl-0
      li.mb-2.d-flex.align-center
        ui-icon.mr-3.c-primary(name="check2")
        .c-black80 Участие в аукционах
      li.mb-2.d-flex.align-center
        ui-icon.mr-3.c-primary(name="check2")
        .c-black80 Размещение актива на продажу
      li.d-flex.align-center
        ui-icon.mr-3.c-primary(name="check2")
        .c-black80 Доступ к закрытой части

    ui-btn.mb-3(
      large
      primary
      width="100%"
      @click="confirm"
    ) Продолжить регистрацию

    ui-btn.mb-3(
      large
      secondary
      width="100%"
      @click="cancel"
    ) Использовать базовый функционал
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon },

    props: {
      user: { type: Object, default: undefined },
    },

    data() {
      return {
        isActive: false,
      };
    },

    methods: {
      open(options) {
        options = options || {};
        this.options = { ...this.options, ...options };
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .welcome-pro-dialog {
    background-color: c('white', 'light');
  }
</style>
