<template lang="pug">
app-layout
  v-app.layout-full
    app-nav-drawer(v-model="navDrawer")
    app-header(no-buttons @nav:click="navDrawer = true")

    v-main
      .page-sign-up-individual.pa-6.pb-8
        router-link.link--no-underline.d-flex.align-center.c-primary.px-0.mb-4(:to="$path.signUp()")
          ui-icon(name="arrow-left" left)
          .t-caption-middle Назад
        .t-h1-mobile Регистрация
        .t-subtitle.c-gray80.mt-1.mb-4 Физическое лицо

        v-text-field.mb-7(
          v-model="name"
          type="text"
          label="ФИО"
          hide-details="auto"
          key="name"
          :error-messages="errorMsg('name')"
        )
          template(v-if="name && !errors.name" #append)
            ui-icon.c-primary(name="check2")
        v-text-field.mb-7(
          v-model="email"
          type="email"
          label="Электронная почта"
          hide-details="auto"
          key="email"
          :error-messages="errorMsg('email')"
        )
          template(v-if="email && emailChecked && !errors.email" #append)
            ui-icon.c-primary(name="check2")
        v-text-field.mb-2(
          v-model="phone"
          type="tel"
          label="Номер мобильного телефона"
          :placeholder="maska.PHONE_PLACEHOLDER"
          hide-details="auto"
          key="phone"
          v-maska="maska.PHONE_VMASK"
          :error-messages="errorMsg('phone')"
          :disabled="regMethod === 'phone'"
        )
          template(v-if="phone && phoneChecked && !errors.phone" #append)
            ui-icon.c-primary(name="check2")

        template(v-if="regMethod === 'phone'")
          .t-caption-middle.c-primary.mb-4(@click="setNumber") Изменить номер телефона
          template(v-if="!checkedSms")
            .t-caption-middle.mb-4 Код отправлен на указанный номер телефона
            v-text-field.mt-4(
              v-model="sms_code"
              label="Код из смс"
              autofocus
              hide-details="auto"
              key="sms_code"
              v-maska="maska.SMS_CODE_VMASK"
              :error-messages="errorMsg('code')"
            )
              template(v-if="checkedSms" #append)
                ui-icon.c-primary(name="check2")
              template(v-else #append)
                ui-countdown-timer(
                  ref="countdownTimer"
                  v-model="codeTimeLeft"
                  key="codeTimeLeft"
                  :duration="resendCodeTimerDuration"
                )
                  .t-small.c-text {{ codeTimeLeft | duration }}

            template(v-if="codeTimeLeft == 0")
              .t-caption-middle.my-4 Не пришла СМС с кодом?&nbsp;
              .t-caption-middle.c-primary.mb-4(@click="resendCode") Отправить повторно
              .t-caption-middle.c-primary.mb-4(@click="regMethod = 'email'") Зарегистрироваться по электронной почте

            ui-btn.my-4(
              large
              secondary
              width="100%"
              @click="confirmSmsCode"
            ) Подтвердить

        template(v-if="regMethod === 'email'")
          v-text-field.mt-4(
            v-model="password"
            type="password"
            label="Придумайте пароль"
            hide-details="auto"
            key="password"
            :error-messages="errorMsg('password')"
          )
            template(v-if="password && passwordChecked && !errors.password" #append)
              ui-icon.c-primary(name="check2")
          v-text-field.mt-4(
            v-model="confirmation_password"
            type="password"
            label="Повторите пароль"
            hide-details="auto"
            key="confirmation_password"
            :error-messages="errorMsg('password_confirmation')"
          )
            template(v-if="confirmation_password && passwordChecked && !errors.password" #append)
              ui-icon.c-primary(name="check2")
        template
          ui-loader.my-6(preloader :loading="phoneLoader")
          ui-btn.my-4(
            v-if="phone && !phoneLoader && !regMethod && phoneChecked"
            large
            secondary
            width="100%"
            @click="confirmPhone"
          ) Подтвердить номер

        .d-flex.align-start.my-6
          v-checkbox.ma-0.pa-0(
            v-model="agreement"
            hide-details="auto"
            key="agreement"
            :error-messages="errorMsg('agreement')"
          )
          .t-caption-normal(:class="agreement ? 'c-black' : 'c-text'")
            | Я принимаю условия&nbsp;
            a.link.link-icon-open-window.link-icon--small.c-primary(
              href=`${process.env.VUE_APP_API_HOST}/documents/agreement.pdf?v1`
              target="_blank"
            ) пользовательского соглашения
            | &nbsp;и&nbsp;согласен на обработку моих&nbsp;
            a.link.link-icon-open-window.link-icon--small.c-primary(
              href=`${process.env.VUE_APP_API_HOST}/documents/personal_data.pdf?v1`
              target="_blank"
            ) персональных данных
        ui-loader.my-6(preloader :loading="regLoader")
        ui-btn(
          large
          primary
          width="100%"
          @click="create"
          :disabled="!disableBtn"
        ) Зарегистрироваться

    welcome-pro-dialog(ref="welcomeProDialog" :user="currentUser")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';

  import { UiBtn, UiIcon, UiLoader, UiCountdownTimer } from '@/uikit';
  import { maska } from 'maska';
  import Constants from '@/uikit/util/constants';
  import WelcomeProDialog from '@/components/auth/WelcomeProDialog.vue';

  import { mapGetters, mapActions } from 'vuex';
  import { service as API } from '@/services/auth';
  import { handleError } from '@/libs/handle-error';
  import { formErrors } from '@/uikit/mixins/formErrors';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageSignUpIndividual', 'blue');

  const SMS_COUNTDOWN_DURATION = 60; // сек

  export default {
    name: 'page-sign-up-individual',
    components: {
      AppLayout,
      AppNavDrawer,
      AppHeader,
      UiBtn,
      UiIcon,
      UiLoader,
      UiCountdownTimer,
      WelcomeProDialog,
    },
    directives: {
      maska,
    },
    mixins: [formErrors],

    data: () => ({
      navDrawer: false,
      loading: false,
      phoneLoader: false,
      regLoader: false,
      name: undefined,
      email: undefined,
      phone: undefined,
      agreement: undefined,
      password: undefined,
      confirmation_password: undefined,
      sms_code: undefined,
      checkedSms: false,
      codeTimeLeft: undefined,
      resendCodeTimerDuration: SMS_COUNTDOWN_DURATION,
      regMethod: undefined,
      errors: [],
      maska: Constants,
      redirectToDataRoom: false,
    }),

    computed: {
      disableBtn() {
        return (
          this.name &&
          this.emailChecked &&
          this.phoneChecked &&
          this.agreement &&
          this.passwordChecked &&
          this.checkMethod()
        );
      },

      phoneChecked() {
        var re = /^\+(7\d{10}|[1-9]\d{0,2}\s\(\d{3}\)\s\d{3}-\d{2}-\d{2})$/;
        return re.test(this.phone);
      },

      emailChecked() {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.email);
      },

      passwordChecked() {
        return this.password === this.confirmation_password;
      },

      verifyType() {
        return this.regMethod === 'phone' ? 'sms' : 'password';
      },

      ...mapGetters(['currentUser']),
    },

    mounted() {
      if (sessionStorage.getItem('phoneToRegister')) {
        let storedPhone = sessionStorage.getItem('phoneToRegister');

        // Проверка наличия 8 в начале и замена на +7
        if (storedPhone.startsWith('8') && storedPhone.length > 2) {
          this.phone = '+7' + storedPhone.slice(1);
        } else if (storedPhone.startsWith('9') && storedPhone.length > 2) {
          this.phone = '+7' + storedPhone;
        } else {
          this.phone = storedPhone;
        }
      }

      if (sessionStorage.getItem('emailToRegister')) {
        this.email = sessionStorage.getItem('emailToRegister');
      }
    },

    methods: {
      checkMethod() {
        if (this.regMethod === 'phone') {
          return !!this.checkedSms;
        } else {
          return !!this.password && !!this.confirmation_password;
        }
      },

      async create() {
        try {
          this.clearErrors();
          this.regLoader = true;

          if (localStorage.getItem('redirectToDataRoom')) {
            this.redirectToDataRoom = true;
          }

          const user_info = {
            name: this.name,
            phone: this.phone,
            email: this.email,
            password: this.password,
            password_confirmation: this.confirmation_password,
            sms_code: this.sms_code,
            user_type: 'natural',
            verify_type: this.verifyType,
            agreement: this.agreement,
            redirect_to_data_room: this.redirectToDataRoom,
          };

          // UTM метки
          if (localStorage.getItem('utms')) {
            user_info.utms = JSON.parse(localStorage.getItem('utms'));
          }

          logger.log('registrations', user_info);

          const { token, refreshToken, user, redirect_uri } = await API.registration(user_info);
          this.signInWithToken({ token, refreshToken, user, redirect_uri });

          this.$notify.info('Вы успешно зарегистрировались и авторизованы');
          if (redirect_uri) {
            window.location.href = redirect_uri
          }
          // идем на про?
          if (await this.$refs.welcomeProDialog.open()) {
            this.$router.push(this.$path.createPRO());
          } else {
            this.$router.push(this.$path.home());
          }
        } catch (error) {
          handleError(error, logger);
          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(
              error.data,
              'name',
              'phone',
              'email',
              'password',
              'password_confirmation',
              'sms_code',
              'agreement'
            );
          }
        } finally {
          this.regLoader = false;
          localStorage.removeItem('redirectToDataRoom');
          this.redirectToDataRoom = false;
        }
      },

      async confirmPhone() {
        try {
          this.clearErrors();
          this.phoneLoader = true;
          const ok = await API.requestCode(this.phone);
          if (ok) this.regMethod = 'phone';
        } catch (error) {
          handleError(error, logger);
          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(error.data, 'phone');
          }
        } finally {
          this.phoneLoader = false;
        }
      },

      setNumber() {
        this.regMethod = undefined;
        this.sms_code = undefined;
        this.checkedSms = false;
        this.clearErrors();
      },

      async confirmSmsCode() {
        try {
          this.clearErrors();
          this.checkedSms = false;
          const data = await API.verifyCode(this.phone, this.sms_code);
          logger.log(data);
          this.checkedSms = data.ok;
        } catch (error) {
          handleError(error, logger);

          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(error.data, 'code', 'phone');
          }
        } finally {
          this.phoneLoader = false;
        }
      },

      async resendCode() {
        this.code = '';
        this.$refs.countdownTimer.resetTimer();
        this.confirmPhone();
      },

      ...mapActions('user', ['signInWithToken']),
    },
  };
</script>

<style lang="scss">
  .page-sign-up-individual {
    .back-link {
      position: relative;
      color: c('primary60', 'light');
      text-decoration: none;
      padding-left: 28px;
      display: block;
      font-size: 14px;

      &::after {
        @include svg-bg-mask-recolored('chevron', c('primary60', 'light'), light);
        transform: rotate(180deg);
        position: absolute;
        content: '';
        top: -2px;
        left: 0px;
        width: 24px;
        height: 24px;
      }
    }
    .ui-loader {
      display: flex;
    }
    .v-text-field__slot label.v-label--active {
      color: #909497 !important;
    }
    .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
      background: none;
    }
  }
</style>
